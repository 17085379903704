<template>
  <div :class="user.is_vessel === true ? 'mt-1' : ''">
    <div class="row no-gutters justify-content-between mb-1" v-if="$route.name !== 'VesselInternalAuditHistory'">
      <div class="col-sm-auto flex-grow-0">
        <h3 class="mb-0 text-left">{{ loading === true ? '' : vessel.name? vessel.name  + ' AUDIT RECORD' : ''}}</h3>
      </div>
      <div class="col-sm-auto">
        <button class="e-btn e-btn-green force-white-all" data-toggle="modal" data-target="#ScheduleNewVesselAuditModal"
                v-if="isAllowed('vessel-ia.appointments.audit-records.create')"
        >
          <font-awesome-icon icon="plus-square" style="width: 25px"/>
          <small class="font-weight-bold px-2">
            NEW VESSEL AUDIT RECORD
          </small>
        </button>
        <button class="ml-2 px-2 py-1 e-btn-blue-gray force-white-all rounded" type="button" @click="redirectToAuditRecordList">
          <font-awesome-icon icon="chevron-left" class="e-text-black"/> Go Back
        </button>
      </div>
    </div>

    <div class="row no-gutters align-items-center justify-content-between">
      <div class="col-sm-auto pb-2 mr-2">
        <nav aria-label="...">
          <ul class="pagination text-smallest mb-0 pb-0">
            <li class="page-item" :class="{ 'disabled': pagination.currentPage === 1}">
              <button class="page-link" @click="setPage(1)">First</button>
            </li>

            <li v-for="page in paginationPages" :key="page" class="page-item" :class="{ 'disabled': page === '...', 'active': page === pagination.currentPage }">
              <button class="page-link" @click="setPage(page)">{{ page }}</button>
            </li>

            <li class="page-item" :class="{ 'disabled': pagination.currentPage === pagination.lastPage}">
              <button class="page-link" @click="setPage(pagination.lastPage)">Last</button>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col-sm-auto pb-2">
        <div class="input-group input-group-sm fa-w-4 float-right">
          <div class="input-group-prepend">
            <label class="input-group-text input-group-sm bg-white small border-0" for="rowCount"># of Row(s):</label>
          </div>
          <select class="form-control form-control-sm rounded-left" id="rowCount" v-model="filters.count">
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="10000">All</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row no-gutters justify-content-between mb-1">
      <div class="col-sm-12" style="max-width: 100%; overflow-x: auto; min-height: 70vh; max-height: 70vh">
        <table class="small e-tbl text-center">
          <thead class="bg-white" style="position: sticky; top: 0; z-index: 2">
          <tr>
            <th class="align-middle" style="width: 1em" scope="col">#</th>
            <th class="align-middle" scope="col" style="position: relative; min-width: 200px">
              AUDIT TYPE 1
              <filter-like-excel
                v-show="loading === false"
                :module="'vessel_audit_record_list'"
                :column-name="'audit_type_1'"
                :filter-option-to-right="true"
                :custom-param-for-getting-menu="customParamForFilterLikeExcel"
                @setFilterParam="setFilterParam"
              />
            </th>
            <th></th>
            <th class="align-middle" scope="col" style="position: relative; min-width: 200px">
              AUDIT TYPE 2
              <filter-like-excel
                v-show="loading === false"
                :module="'vessel_audit_record_list'"
                :column-name="'audit_type_2'"
                :custom-param-for-getting-menu="customParamForFilterLikeExcel"
                @setFilterParam="setFilterParam"
              />
            </th>
            <th class="align-middle" scope="col" style="position: relative; min-width: 120px">
              AUDIT TYPE 3
              <filter-like-excel
                v-show="loading === false"
                :module="'vessel_audit_record_list'"
                :column-name="'audit_type_3'"
                :custom-param-for-getting-menu="customParamForFilterLikeExcel"
                @setFilterParam="setFilterParam"
              />
            </th>
            <th class="align-middle" scope="col" style="position: relative; min-width: 200px">
              AUDIT DATE
              <filter-like-excel
                v-show="loading === false"
                :module="'vessel_audit_record_list'"
                :column-name="'audit_date'"
                :custom-param-for-getting-menu="customParamForFilterLikeExcel"
                @setFilterParam="setFilterParam"
              />
            </th>
            <th class="align-middle" scope="col" style="position: relative; min-width: 170px">
              APPOINTMENT DATE
              <filter-like-excel
                v-show="loading === false"
                :module="'vessel_audit_record_list'"
                :column-name="'appointment_date'"
                :custom-param-for-getting-menu="customParamForFilterLikeExcel"
                @setFilterParam="setFilterParam"
              />
            </th>
            <th class="align-middle" scope="col" style="position: relative; min-width: 200px">
              AUDIT PLACE
              <filter-like-excel
                v-show="loading === false"
                :module="'vessel_audit_record_list'"
                :column-name="'audit_place'"
                :custom-param-for-getting-menu="customParamForFilterLikeExcel"
                @setFilterParam="setFilterParam"
              />
            </th>
            <th class="align-middle" scope="col" style="position: relative; min-width: 150px">
              AUDITOR(S)
<!--              <filter-like-excel-->
<!--                v-show="loading === false"-->
<!--                :module="'vessel_audit_record_list'"-->
<!--                :column-name="'auditors'"-->
<!--                :custom-param-for-getting-menu="customParamForFilterLikeExcel"-->
<!--                @setFilterParam="setFilterParam"-->
<!--              />-->
            </th>
            <th class="align-middle" scope="col" style="position: relative; min-width: 120px">
              STATUS
              <filter-like-excel
                v-show="loading === false"
                :module="'vessel_audit_record_list'"
                :column-name="'report_status_id'"
                :custom-param-for-getting-menu="customParamForFilterLikeExcel"
                @setFilterParam="setFilterParam"
              />
            </th>
            <th class="align-middle" scope="col" style="position: relative; min-width: 200px">
              INTERNAL AUDIT SCORE
              <filter-like-excel
                v-show="loading === false"
                :module="'vessel_audit_record_list'"
                :column-name="'audit_score'"
                :custom-param-for-getting-menu="customParamForFilterLikeExcel"
                @setFilterParam="setFilterParam"
              />
            </th>
            <th class="align-middle" scope="col" style="position: relative; min-width: 120px">
              EXTENSION
              <filter-like-excel
                v-show="loading === false"
                :module="'vessel_audit_record_list'"
                :column-name="'is_extended'"
                :custom-param-for-getting-menu="customParamForFilterLikeExcel"
                @setFilterParam="setFilterParam"
              />
            </th>
            <th class="align-middle" scope="col" style="position: relative; min-width: 200px">
              CREATED BY
              <filter-like-excel
                v-show="loading === false"
                :module="'vessel_audit_record_list'"
                :column-name="'created_by'"
                :custom-param-for-getting-menu="customParamForFilterLikeExcel"
                @setFilterParam="setFilterParam"
              />
            </th>
            <th class="align-middle" scope="col" style="position: relative; min-width: 200px">
              CREATED AT
            </th>
            <th class="align-middle" scope="col" style="position: relative;">ACTION</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="loading">
            <td colspan="100%">
              <loading></loading>
            </td>
          </tr>
          <template v-else v-for="(audit_record, index) in vesselAuditRecord">
            <template v-for="(type, type_index) in getAuditTypeNotNull(audit_record)">
              <tr class="ia-vessel-list no-select" @mouseover="highlightData(index, true)" @mouseout="highlightData(index, false)" @dblclick="viewAuditRecord(audit_record)">
                <td :class="'list_' + index" v-if="type_index === 0" :rowspan="getAuditTypeNotNull(audit_record).length">{{ index + 1 }}</td>
                <td :class="'list_' + index" class="text-uppercase" v-if="type_index === 0" :rowspan="getAuditTypeNotNull(audit_record).length">{{ getAuditTypeNotNull(audit_record).join(' & ')}}</td>
                <td :class="'list_' + index" class="text-uppercase">
                  {{ type }}
                </td>
                <td
                  v-if="
                    (type in getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'audit_type_2') === true &&
                    getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'audit_type_2')[type] === true) ||
                    (type in getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'audit_type_2') === false)"
                  :rowspan="type in getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'audit_type_2') === false ? 1 : Object.keys(getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'audit_type_2')).length"
                  :class="'list_' + index">{{ convertAuditType2IntoType(audit_record[type].audit_type_2) }}</td>
                <td
                  v-if="
                    (type in getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'audit_type_3') === true &&
                    getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'audit_type_3')[type] === true) ||
                    (type in getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'audit_type_3') === false)"
                  :rowspan="type in getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'audit_type_3') === false ? 1 : Object.keys(getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'audit_type_3')).length"
                  :class="'list_' + index"
                >
                  {{ convertAuditType3IntoType(audit_record[type].audit_type_3) }}
                </td>
                <td
                  v-if="
                    (type in getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'audit_start_end_date') === true &&
                    getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'audit_start_end_date')[type] === true) ||
                    (type in getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'audit_start_end_date') === false)"
                  :rowspan="type in getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'audit_start_end_date') === false ? 1 : Object.keys(getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'audit_start_end_date')).length"
                  :class="'list_' + index">
                  {{ audit_record[type].audit_date_start.human === audit_record[type].audit_date_end.human ? audit_record[type].audit_date_end.human : audit_record[type].audit_date_start.human + ' - ' + audit_record[type].audit_date_end.human }}
                </td>
                <td
                  v-if="
                    (type in getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'appointment_date_picker') === true &&
                    getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'appointment_date_picker')[type] === true) ||
                    (type in getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'appointment_date_picker') === false)"
                  :rowspan="type in getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'appointment_date_picker') === false ? 1 : Object.keys(getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'appointment_date_picker')).length"
                  :class="'list_' + index">
                  {{ audit_record[type].appointment_date.human }}
                </td>
                <td
                  v-if="
                    (type in getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'audit_place') === true &&
                    getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'audit_place')[type] === true) ||
                    (type in getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'audit_place') === false)"
                  :rowspan="type in getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'audit_place') === false ? 1 : Object.keys(getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'audit_place')).length"
                  :class="'list_' + index"
                > {{ audit_record[type].audit_place }}</td>
                <td
                  v-if="
                    (type in getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'auditor_ids') === true &&
                    getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'auditor_ids')[type] === true) ||
                    (type in getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'auditor_ids') === false)"
                  :rowspan="type in getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'auditor_ids') === false ? 1 : Object.keys(getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'auditor_ids')).length"
                  :class="'list_' + index"
                >
                  <div v-for="auditor in audit_record[type].auditors" class="text-left">
                    {{ auditor.name }} <strong>{{ (auditor.is_lead === true ? ' (Lead)' : '')}}</strong>
                  </div>
                </td>
                <td
                  v-if="
                    (type in getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'report_status_id') === true &&
                    getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'report_status_id')[type] === true) ||
                    (type in getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'report_status_id') === false)"
                  :rowspan="type in getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'report_status_id') === false ? 1 : Object.keys(getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'report_status_id')).length"
                  :class="['bg-'+convertReportStatus(audit_record[type].report_status_id, true).color]"> {{ convertReportStatus(audit_record[type].report_status_id, true).name }}</td>
                <td
                  v-if="
                    (type in getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'audit_score') === true &&
                    getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'audit_score')[type] === true) ||
                    (type in getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'audit_score') === false)"
                  :rowspan="type in getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'audit_score') === false ? 1 : Object.keys(getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'audit_score')).length"
                  :class="'list_' + index">{{ audit_record[type].audit_score }}</td>
                <td
                  v-if="
                    (type in getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'is_extended') === true &&
                    getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'is_extended')[type] === true) ||
                    (type in getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'is_extended') === false)"
                  :rowspan="type in getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'is_extended') === false ? 1 : Object.keys(getSameDataFromType(index, getAuditTypeNotNull(audit_record), audit_record, 'is_extended')).length"
                  :class="'list_' + index">{{ audit_record[type].is_extended === 0 ? 'NO' : 'YES'}}</td>
                <td :class="'list_' + index" v-if="type_index === 0" :rowspan="getAuditTypeNotNull(audit_record).length">{{ audit_record?.created_by?.name }}</td>
                <td :class="'list_' + index" v-if="type_index === 0" :rowspan="getAuditTypeNotNull(audit_record).length">{{ audit_record?.created_by?.created_at | moment('D MMMM YYYY') }}</td>
                <td :class="'list_' + index" v-if="type_index === 0" :rowspan="getAuditTypeNotNull(audit_record).length">
                  <div v-if="$route.name !== 'VesselInternalAuditHistory'" class="dropdown dropright">
                    <button
                      v-if="isAllowed('vessel-ia.appointments.audit-records.view') ||
                             isAllowed('vessel-ia.appointments.audit-records.delete')"
                      id="actionDropdown"
                      aria-expanded="false"
                      aria-haspopup="true"
                      class="mr-2 btn btn-sm btn-secondary force-white-all"
                      data-toggle="dropdown"
                    >
                      <font-awesome-icon icon="cogs"/>
                    </button>
                    <div aria-labelledby="actionDropdown" class="dropdown-menu py-0" style="min-height: 50px"
                    >
                      <button class="dropdown-item text-secondary font-weight-bolder"
                              v-if="isAllowed('vessel-ia.appointments.audit-records.view')"
                              @click="viewAuditRecord(audit_record)">
                        <font-awesome-icon icon="eye"/>
                        View
                      </button>
                      <button class="dropdown-item text-secondary font-weight-bolder"
                              v-if="isAllowed('vessel-ia.appointments.audit-records.view')"
                              @click="viewAuditRecordNewTab(audit_record)">
                        <font-awesome-icon icon="eye"/>
                        View on New Tab
                      </button>

                      <template v-for="(audit_type, type_index) in getAuditTypeNotNull(audit_record)">
                        <template v-if="audit_record[audit_type] !== null">
                          <template v-if="audit_record[audit_type].report_status_id === 1">
                            <button class="dropdown-item text-secondary font-weight-bolder text-danger"
                                    v-if="isElite() || user.id === audit_record.created_by.id"
                                    @click="deleteSelected(audit_record[audit_type].id, audit_type)"
                            >
                              <font-awesome-icon icon="trash" class="force-danger-all"/>
                              Delete {{ audit_type.toUpperCase() }} ({{ convertReportStatus(audit_record[audit_type].report_status_id, true).name }})
                            </button>
                          </template>
                          <template v-else>
                            <button class="dropdown-item text-secondary font-weight-bolder text-danger"
                                    v-if="isElite() || isAllowed('vessel-ia.appointments.audit-records.delete')"
                                    @click="deleteSelected(audit_record[audit_type].id, audit_type)"
                            >
                              <font-awesome-icon icon="trash" class="force-danger-all"/>
                              Delete {{ audit_type.toUpperCase() }} ({{ convertReportStatus(audit_record[audit_type].report_status_id, true).name }})
                            </button>
                          </template>
                        </template>
                      </template>
                    </div>
                  </div>
                  <template v-else>
                    <button v-else class="e-btn e-btn-blue e-btn-sm e-text-white"
                            v-if="isAllowed('vessel-ia.appointments.audit-records.view')"
                            @click="viewAuditRecord(audit_record)">
                      <font-awesome-icon icon="eye"/>
                      View
                    </button>
                  </template>
                </td>
              </tr>
            </template>

          </template>

          </tbody>
        </table>
      </div>
    </div>
    <ScheduleAudit/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {vueAppMixin} from "@/mixins/vueAppMixin";
import AppLoading from "@/components/elements/AppLoading";

import _ from 'lodash';
import {AlertService} from "@/services/AlertService";
import {PaginationMixin} from "@/mixins/PaginationMixin";
import ScheduleAudit from "@/components/modules/ia/vessel/ScheduleAudit.vue";
import loading from "@/components/elements/AppLoading.vue";
import AuditRecordMixin from "@/mixins/AuditRecordMixin";
import FilterLikeExcel from "@/components/common/FilterLikeExcel.vue";


export default {

  mixins: [vueAppMixin,PaginationMixin, AuditRecordMixin],
  components: {loading, AppLoading, ScheduleAudit, FilterLikeExcel},

  name: 'VesselAuditRecordList',
  data() {
    return {
      vesselID: null,
      filters: {
        page: 1,
        count: 30,
        query : '',
        vessel_id : this.$route.params.vessel_id
      },
      requestPayload : {
        filter : {},
        sort : '',
        page : 1,
        active: 1,
        search: '',
        query : '',
        count: 30,
        vessel_id : this.vesselId
      },
      allModuleFilterParams : [],
      loading : false,
      customParamForFilterLikeExcel : [
        {
          key : 'vessel_id',
          value : this.$route.params.vessel_id
        }
      ]
    }
  },
  methods: {
    setFilterParam(filterParam, dataLength) {
      const moduleFilterParamIndex = this.allModuleFilterParams.findIndex(item => item.column_name === filterParam.column_name);
      if (moduleFilterParamIndex === -1) {
        if (filterParam.direction === '' && filterParam.where_in.length === 0) {
          return;
        }
        this.allModuleFilterParams.push(filterParam);
      } else {
        if (filterParam.direction === '' && filterParam.where_in.length === dataLength) {
          this.allModuleFilterParams.splice(moduleFilterParamIndex, 1)
        } else {
          this.allModuleFilterParams[moduleFilterParamIndex] = filterParam;
        }
      }

      this.requestPayload.filter = {};
      this.allModuleFilterParams.forEach(item => {
        this.requestPayload.filter[item.column_name] = item.where_in;
      });

      const sortIndex = this.allModuleFilterParams.findIndex(item => item.direction !== '');
      if (sortIndex === -1) {
        this.filterAuditRecordList();
        return;
      }
      const filterParamWithSort = this.allModuleFilterParams[sortIndex];
      const sortSign = filterParamWithSort.direction === 'desc' ? '-' : '';
      this.requestPayload.sort = sortSign + filterParamWithSort.column_name;
      this.filterAuditRecordList();
    },
    highlightData(rowIndex, hover) {
      const allList = $('.list_' + rowIndex);
      for (let counter = 0; counter < allList.length; counter++) {
        if (hover === true) {
          $(allList[counter]).addClass('list-hover')
        } else {
          $(allList[counter]).removeClass('list-hover')
        }
      }
    },
    redirectToAuditRecordList() {
      return this.$router.push({name: 'VesselList'});
    },
    async viewAuditRecord(vesselAuditRecord) {
      await this.$router.push(
        {
          name: 'internalAuditReport',
          params: {
            id: vesselAuditRecord.id,
            vessel_id : vesselAuditRecord.vessel_id,
            type : this.getFirstLandingType(vesselAuditRecord)
          },
        },

      ).catch(() => {})
    },
    async viewAuditRecordNewTab(vesselAuditRecord) {
      const route= this.$router.resolve(
        {
          name: 'internalAuditReport',
          params: {
            id: vesselAuditRecord.id,
            vessel_id : vesselAuditRecord.vessel_id,
            type : this.getFirstLandingType(vesselAuditRecord)
          },
        },
      )
      window.open(route.href,'_blank')
    },
    async filterAuditRecordList() {
      this.loading = true;
      await this.getVesselAuditRecordList(this.requestPayload);
      this.loading = false;
    },
    async deleteSelected(audit_type_id, audit_type) {
      if(await AlertService.confirmDeleteAlert('Delete ' + audit_type.toUpperCase() + ' Audit Record', 'Are you sure you want to delete this Audit Record?', 'Delete')){
        swal.fire({
          title:'ENTER YOUR PASSWORD',
          input: 'password',
          inputAttributes: {
            autocapitalize: 'off'
          },
          confirmButtonText:'PROCEED DELETION',
          cancelButtonText: 'CANCEL',
          showCancelButton:true,
          allowOutsideClick:false,
          allowEscapeKey:false,
          allowEnterKey:false,
          confirmButtonColor:'#dc3545',
          showLoaderOnConfirm:true,
          preConfirm:async (inputValue)=> {
            let response = await this.deleteAuditRecord({audit_type_id: audit_type_id, audit_type : audit_type, password: inputValue});
            if (typeof response === 'boolean' && response) {
              return response;
            } else {
              if (response.hasOwnProperty('message') && !response.hasOwnProperty('errors')) {
                swal.showValidationMessage(response.message);
              }
              else{
                swal.showValidationMessage(response.errors.password.join(','));
              }
            }
          }
        })
          .then(async (result)=>{
            if(result.isConfirmed){
              await this.successAlert('AUDIT RECORD DELETED SUCCESSFULLY','DELETE AUDIT RECORD');
              await this.filterAuditRecordList();
            }
          });
      }
    },
    async setPage(page) {
      this.pagination.currentPage = page;
      this.requestPayload.page = this.pagination.currentPage
      await this.getVesselAuditRecordList(this.requestPayload);
    },
  },
  async created() {
    this.requestPayload.vessel_id = this.vesselId
    this.searchAuditRecord = _.debounce(this.filterAuditRecordList, 750);
    await this.filterAuditRecordList();
    this.loading = true;
    await this.addRequestKeys([
      'getReportStatusKeys'
    ]);
    await this.generateRequestKeys({});

    await this.getVesselDetail(this.vesselId);
    this.loading = false;

  },
  watch : {
    async 'filters.count'(count) {
      this.requestPayload.count = count
      this.requestPayload.page = 1
      await this.filterAuditRecordList();
    },
    async 'filters.query'(query){
      this.requestPayload.query = query;
      this.searchAuditRecord();
    }
  },
  computed: {
    vesselId(){
      return this.$route.name === 'VesselInternalAuditHistory' ? this.$route.params.id : this.$route.params.vessel_id
    }
  }
}
</script>

<style scoped lang="scss">
td {
  padding: 3px;
}
</style>
